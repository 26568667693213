<template>
  <div>
    <vx-card :title="'Contrat(s) (' + allProjetContrats.list.length +')'" class="mb-4">
      <div slot="no-body">
        <div class="w-auto" v-if="loading">
          <clip-loader :loading="loading" color="primary"></clip-loader>
        </div>
        <div v-else class="p-8">
          <!--<div class=" mb-4">
            <h2>Contrat(s) ({{ allProjetContrats.list.length }})</h2>
          </div>-->
          <div>
            <div class>
              <div class="w-auto" v-if="loading">
                <clip-loader :loading="loading" color="primary"></clip-loader>
              </div>
              <div v-else>
                <div v-if="allProjetContrats.list.length !== 0">
                  <vs-table
                    @selected="popupAllProduct = true"
                    v-model="contratSelected"
                    :data="allProjetContrats.list"
                    search
                    stripe
                    pagination
                    :max-items="limit != 'Tout' ? limit : total"
                    noDataText="Aucun résultat trouvé"
                  >
                    <template slot="thead">
                      <vs-th>N°</vs-th>
                      <vs-th sort-key="intermediary">Intermediaire</vs-th>
                      <vs-th sort-key="product">Produit</vs-th>
                      <vs-th sort-key="ref">Référence</vs-th>
                      <vs-th sort-key="period">Période</vs-th>
                      <vs-th sort-key="status">Etat</vs-th>
                      <vs-th >Actions</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr
                        :data="tr"
                        :key="indextr"
                        v-for="(tr, indextr) in data"
                      >
                        <vs-td :data="data[indextr].id">{{
                          indextr + 1
                        }}</vs-td>

                        <vx-tooltip
                          position="top"
                          color="primary"
                          title="Informations"
                          :text="
                            `email: ${data[indextr].broker.email}  téléphone: ${data[indextr].broker.person.phone}`
                          "
                        >
                          <div class="cursor-pointer">
                            <vs-td :data="data[indextr].broker">
                              {{
                                `${data[indextr].broker.person.firstname} ${data[indextr].broker.person.lastname}`
                              }}
                              <span v-if="data[indextr].broker.person.forSociety">
                                ({{data[indextr].broker.person.forSociety}}) 
                              </span>
                            </vs-td>
                          </div>
                        </vx-tooltip>
                        <vs-td :data="data[indextr].product">{{
                          productList.find(
                            pro => pro.id == data[indextr].productName
                          ).label
                        }}</vs-td>
                        <vs-td :data="data[indextr].ref">{{
                          data[indextr].ref
                        }}</vs-td>
                        <vs-td :data="data[indextr].avenants">
                          <div v-if="data[indextr].getDefaultAvenant">
                            {{
                              `${formatDate(data[indextr].getDefaultAvenant.startDateContributions)} 
                                - 
                              ${formatDate(data[indextr].getLastAvenant.endDateContributions)}`
                            }}
                          </div>
                        </vs-td>
                        <vs-td :data="data[indextr].status">{{
                          allStatus.find(
                            stat => stat.id == data[indextr].status
                          ).label
                        }}</vs-td>
                        <vs-td :data="data[indextr].id">
                          <div class="inline-flex" @click.stop>
                            <div class=" cursor-pointer">
                              <vx-tooltip
                                v-if="checkUserRole('project_contrat').show"
                                color="primary"
                                text="Télécharger"
                              >
                                <div
                                  class="ml-1 cursor-pointer"
                                  @click="getFilesList(data[indextr])"
                                >
                                  <span class="flex items-center">
                                    <feather-icon
                                      icon="DownloadIcon"
                                      class="text-primary"
                                      svgClasses="w-6 h-6"
                                    ></feather-icon>
                                  </span>
                                </div>
                              </vx-tooltip>
                              <vx-tooltip
                                v-else
                                color="primary"
                                :title="role.notAllowTitle"
                                :text="role.notAllowText"
                              >
                                <vs-dropdown-item disabled>
                                  <div class="ml-1 cursor-pointer">
                                    <span class="flex items-center">
                                      <feather-icon
                                        icon="DownloadIcon"
                                        class="text-primary"
                                        svgClasses="w-6 h-6"
                                      ></feather-icon>
                                    </span>
                                  </div>
                                </vs-dropdown-item>
                              </vx-tooltip>
                            </div>
                          </div>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                  <vs-pagination
                    class="mt-6"
                    :total="allPages"
                    v-model="currentPage"
                  ></vs-pagination>

                  <vs-popup
                    class="holamundo"
                    :title="
                      contratSelected.subscription ? `${contratSelected.subscription.subscriber.firstname} ${contratSelected.subscription.subscriber.lastname}`
                      : ''
                    "
                    fullscreen
                    :active.sync="popupAllProduct"
                  >
                    <div class="" v-if="contratSelected">
                      <div v-if="contratSelected.productName == 'SOHU'">
                        <aps-benin-sohu
                          v-on:onDisplayPopup="onDisplayPopup"
                          :dataInfo="contratSelected ? contratSelected : {}"
                        ></aps-benin-sohu>
                      </div>
                    </div>
                  </vs-popup>
                </div>
                <div v-else>
                  <div class="flex items-center justify-center mt-8">
                    <strong>Aucun enregistrement</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>

    <vs-popup
      class="holamundo"
      title="Liste des fichiers"
      :active.sync="popupListFiles"
    >
      <div v-if="!isLoadingFilesList">
        <div v-if="filesList.length != 0" class="m-4">
          <p
            v-for="item in filesList"
            :key="item.filename"
            style="cursor: pointer"
            @click="checkViewFile(item)"
          >
            <b
              ><span> {{ item.name }} </span></b
            >
            <vs-divider />
          </p>
        </div>
        <div v-else>
          <div class="flex items-center justify-center mt-8">
            <strong>Aucun enregistrement</strong>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="w-auto" v-if="isLoadingFilesList">
          <clip-loader :loading="isLoadingFilesList" color="primary"></clip-loader>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mixin } from "@/mixins/mixin";
import moment from "@/helpers/moment";
import role from "@/config/role";
import global from "@/config/global";
import Multiselect from "vue-multiselect";

export default {
  name: "aps-benin-contrat",
  components: {
    ClipLoader,
    Multiselect
  },
  data() {
    return {
      productList: [],

      contratSelected: {},
      popupAllProduct: false,
      allStatus: [],
      popupListFiles: false,
      filesList: [],
      isLoadingFilesList: false,
      loading: false,
      global: {},
      //Pagination
      limit: 1,
      skip: 0,
      currentPage: 1,
      total: 0,
      allPages: 1,
      //Pagination
      //export section
      fileName: "",
      formats: ["xlsx", "csv", "txt", "pdf"],
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: ["N°", "Nom", "Prénom", "Nom d'utilisateur", "Email"],
      headerVal: ["index", "lastname", "firstname", "username", "status"],
      activePrompt: false,
      //end export section
      projectSelected: [],
      crrIDs: []
    };
  },
  created() {
    this.role = role;
    this.global = global;
    Validator.localize("fr", VeeValidateFR);
    this.productList = this.allProduct;
    console.log("allContratStatus", this.allProduct);
    this.allStatus = this.allContratStatus;
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("projetContrat", ["allProjetContrats"]),
    ...mapGetters("general", ["rowsTable", "allProduct", "allContratStatus", "allDocClient"])
  },
  mounted() {
    this.crrModuleName = this.$route.params.crrModuleName;
    this.limit = this.rowsTable[1];
  },

  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("projetContrat", ["applyGetAllProjetContrat"]),
    ...mapActions("general", ["applyGetItemDocument"]),

    displayStatus(status) { 
      return this.allStatus.find(el => el.id == status).label;
    },

    onDisplayPopup(isClose) {
      this.popupAllProduct = isClose;
    },
    checkViewProject(item) {},

    acceptAlert(color, title, text) {
      this.$vs.notify({ color, title, text });
    },
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },
    lastLogin(data) {
      return mixin.methods.lastLogin(data.lastLoginAt);
    },
    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },
    async getFilesList(item) {
      this.filesList = []
      this.isLoadingFilesList = true
      this.popupListFiles = true;

      let conditionGeneral, ficheProduit

      if(item.productName === "SOHU") {
        conditionGeneral = "by_condition_general_sohu"
        ficheProduit = "by_fiche_produit_sohu"
      }

      // Fetch Condition General Produit
      let resConditionGeneralDoc = await this.applyGetItemDocument({
        code: conditionGeneral,
        productName: this.allDocClient[item.productName],
      })
      // Fetch Fiche Produit
      let resFicheProductDoc = await this.applyGetItemDocument({
        code: ficheProduit,
        productName: this.allDocClient[item.productName],
      })

      this.filesList.push({
        filename: resConditionGeneralDoc.data.filename,
        name: resConditionGeneralDoc.data.name,
      })
      this.filesList.push({
        filename: resFicheProductDoc.data.filename,
        name: resFicheProductDoc.data.name,
      })

      this.isLoadingFilesList = false
    },

    checkViewFile(item) {
      console.log("item", item);
      window.open(this.showFileData(item.filename), "_blank");
    },

    showFileData(data) {
      return `${this.$config.serverURL}/downloads/${data}`;
    },

    exportToData() {
      if (this.selectedFormat == "pdf") {
        let tableToPrintPdf = [];

        this.allProjetContrats.list.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            type: this.profilList.find(type => type.id == el.type).label,
            firstname: el.person.firstname,
            lastname: el.person.lastname,
            username: el.username,
            email: el.email
          };
          tableToPrintPdf.push(jsonTmp);
        });

        let headerArray = [];

        this.headerTitle.forEach((title, index) => {
          headerArray.push({ header: title, dataKey: this.headerVal[index] });
        });
        mixin.methods.exportToPdf(
          [...tableToPrintPdf],
          headerArray,
          this.fileName,
          "LES INTERMEDIAIRES"
        );
      } else {
        let tableToPrint = [];
        this.allProjetContrats.list.forEach(el => {
          let jsonTmp = {
            type: this.profilList.find(type => type.id == el.type).label,
            lastname: el.person.lastname,
            firstname: el.person.firstname,
            username: el.username,
            email: el.email,
            profession: el.profession,
            status: el.status
          };
          tableToPrint.push(jsonTmp);
        });

        let headerValTmp = ["type", "firstname", "lastname", "email", "status"];

        let headerTitleTmp = ["Profil", "Prénom(s)", "Nom", "Email", "Statut"];

        mixin.methods.exportToData(
          tableToPrint,
          headerTitleTmp,
          headerValTmp,
          this.fileName,
          this.cellAutoWidth,
          this.selectedFormat
        );
      }
      this.clearFields();
    },

    clearFields() {
      (this.filename = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },
    dateElement(data) {
      return mixin.methods.dateElement(data);
    },
    formatDate(data) {
      return mixin.methods.formatDate(data);
    },

    async fetchApplyGetAllProject(data) {
      this.loading = true;

      try {
        await this.applyGetAllProjetContrat(data);

        console.log("allProjetContrats", this.allProjetContrats);

        this.total = this.allProjetContrats.total;
        let p = Math.ceil(this.total / this.limit);
        this.allPages = p ? p : 1;

        this.loading = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Utilisateurs", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Utilisateurs",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    projectsSelectedDouble(item) {
      if (this.checkUserRole("project_contrat").show) {
        this.checkViewProject(item);
      } else {
        this.acceptAlert(
          "danger",
          this.role.notAllowTitle,
          this.role.notAllowText
        );
      }
    }
  },
  watch: {
    currentPage() {
      this.skip = (this.currentPage - 1) * this.limit;

      this.fetchApplyGetAllProject({
        limit: this.limit,
        skip: this.skip,
        client: this.user.id,
        status: ["active", "inactive", "pendingInactive"],
      });
    },
    limit() {
      this.skip = 0;
      this.fetchApplyGetAllProject({
        limit: this.limit,
        skip: this.skip,
        client: this.user.id,
        status: ["active", "inactive", "pendingInactive"],
      });
    }
  }
};
</script>

<style scoped lang="scss">
.ql-editor {
  height: 100px !important;
}
</style>
